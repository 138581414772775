<template>
    <div class="bg">
        <div class="topCard">
            <div class="card">
                <img src="../../assets/img/banner.png" alt="">
                <div class="con">
                    <h4>同仁堂大药房(光谷大道店)</h4>
                    <p>在线咨询、急速发货、正品授权</p>
                </div>
            </div>
            <van-search
                v-model="value"
                placeholder="请输入搜索关键词"
                input-align = "center"
                left-icon = 'none'
                show-action
            >
            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
            </van-search>
        </div>
        <div class="title">
            <p>店铺优惠</p>
            <span>更多</span>
        </div>
        <div class="banner">
            <img @click="isShowSheet = true" src="../../assets/img/ticket.png" alt="">
            <img @click="goinfo('card')" src="../../assets/img/ticket2.png" alt="">
        </div>
        <div class="title">
            <p>全部商品</p>
            <span>更多</span>
        </div>
        <div class="tabs">
            <van-tabs v-model="active" animated :swipeable='true'>
                <van-tab title="慢性病药">
                    <div class="tabs_item clearfloat">
                        <ul>
                            <li>消化肠胃</li>
                            <li>骨科药房</li>
                        </ul>
                    </div>
                    <list></list>
                </van-tab>
                <van-tab title="感冒发烧">
                    <div class="tabs_item clearfloat">
                        <ul>
                            <li>零售中药房</li>
                            <li>骨科药房</li>
                        </ul>
                    </div>
                    <list></list>
                </van-tab>
                <van-tab title="消化肠胃">
                    <div class="tabs_item clearfloat">
                        <ul>
                            <li>零售中药房</li>
                            <li>骨科药房</li>
                        </ul>
                    </div>
                    <list></list>
                </van-tab>
                <van-tab title="" disabled></van-tab>
            </van-tabs>
            <div class="shop_car">
                <img src="../../assets/img/car.png" alt="">
            </div>
        </div>

        <!-- 优惠券 -->
        <van-action-sheet v-model="isShowSheet" title="优惠券">
            <div class="ticketList">
                <li>
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
                <li>
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
                <li>
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
                <li>
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
                <h4>已领取</h4>
                <li>
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
                <li class="usered">
                    <p class="lever">白银会员卡可用</p>
                    <div class="con">
                        <p class="price"><span>¥</span>30</p>
                        <div class="time">
                            <p>满30元可用</p>
                            <span>2020.12.05-2020.12.12</span>
                        </div>
                        <p class="get">一键领取</p>
                    </div>
                </li>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import list from '@/components/common/list'
export default {
    name:"pharmacyInfo",
    components:{
        list
    },
    data(){
        return{
            isShowSheet: false
        }
    },
    methods:{
        onSearch(){},

        goinfo(names){
            this.$router.push({name: names})
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.van-tabs__nav { background: #fff; }
/deep/.tabs .van-tab .van-tab__text { font-size: 18px !important; color: #444; font-weight: bold;} 
/deep/.van-tabs__line { background: #1DAEFC; width: 72px; height: 6px;} 
/deep/.van-search { padding: 16px 0 0 0;} 
/deep/.van-search__content { border-radius: 18px;}
/deep/.van-search__action { padding: 0 20px; color: #1DAEFC} 
.bg { 
    width: 100vw; height: auto; background: #f5f5f5;
    .topCard {
         background: #fff; width: 100%; padding: 16px;
        .card{
            display: flex; justify-content: center; align-items: center; flex-direction: row;
            width: 343px; height: 112px; background: #fff; border-radius: 4px; margin: 0 auto; border: 1px solid #D8D8D8;
            img { width: 80px; height: 80px; border-radius: 2px;}
            .con { width: 213px; height: 80px; margin-left: 16px;
                h4 { font-size: 16px; color: #2D2D2D; font-weight: bold; line-height: 18px} 
                p { margin-top: 16px; font-size: 12px; color: #1DAEFC; line-height: 18px;} 
            }
        }  
    }
    .title { width: 100%; padding: 16px; height: 54px; 
        p { font-size: 16px; color: #444444; float: left; font-weight: bold;}
        span { float: right; display: block; font-size: 12px; color: #C2C2C2; background: url('../../assets/icon/right.png') no-repeat right center; background-size: 6px 11px; padding-right: 15px;}  
    } 
    .banner { width: 343px; display: block; margin: 0 auto;
        img { width: 343px;}
        img:first-child { margin-bottom: 8px;}  
    } 
    .tabs {
        .tabs_item { 
            padding: 16px; background: #fff;
            li { font-size: 18px; color: #444; float: left; font-weight: bold; margin-right: 24px;}
        } 
        .all { text-align: right; font-size: 14px; color: #2d2d2d; padding: 0 16px 24px 0;} 
    }
    .shop_car { position: fixed; right: 16px; bottom: 140px; width: 48px; height: 48px;
        img { width: 100%;} 
    } 
    .ticketList { max-height: calc(100vh - 100px); width: 100%;  padding-bottom: 8px;
        li { width: 343px; height: 80px; background: url('../../assets/img/ticket_bg.png') no-repeat left top; background-size: 343px 80px; list-style: none; margin: 0 auto; margin-bottom: 8px;
            p.lever { font-size: 10px; color: #FB5151; line-height: 14px; padding: 3px 0 7px 70px;} 
            .con { width: 100%;
                p.price { font-size: 36px; color: #FB5151; font-weight: bold; float: left; margin-left: 24px;
                    span { font-size: 20px; margin-right: 8px;} 
                } 
                .time { width: 140px; float: left; margin-left: 18px;
                    p { font-size: 12px; color: #FB5151; font-weight: bold; line-height: 16px; margin: 3px 0;}
                    span { font-size: 10px; color: #FB5151; line-height: 14px; } 
                } 
                p.get { width: 88px; border-left: 2px dashed #FB5151; font-size: 14px; line-height: 35px; font-weight: bold; float: right; text-align: center; color: #FB5151; } 
            } 
        } 
        h4 { font-size: 16px; color: #444444; font-weight: bold; padding: 17px 16px; line-height: 21px; }
        li.usered { filter: grayscale(100%);}
        li:last-child { margin-bottom: 0;} 
    } 
} 
</style>